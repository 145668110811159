import React from "react"
import { Col, Row } from 'reactstrap'
import { Link } from "gatsby"


function OfferSection({ price }) {
  return (
    <section id="offer" className="offer py-0 py-md-5 pb-5">
      <Row className="section__title">
          <Col lg={8} sm={12}>
            <Row>
              <Col lg={3}></Col>
              <Col lg={3}>
                  <h1 className="pl-3 pl-sm-0">
                   Oferta
                  </h1>
              </Col>
            </Row>
            <Row>
              <Col lg={5} sm={5} xs={6}>
                <div className="under-titler"></div>
              </Col>
            </Row>
        </Col>
      </Row>
    <Row className="section d-flex justify-content-center pt-md-5 pt-3 ">
      <Col md={8}>
        <Row className="justify-content-center">
          <Col md={1}  xs={2} className="offer__plus"><span>+</span></Col>
          <Col md={9}  xs={10} className="offer__factors">
            <ul className=" pr-md-0 pr-1">
              <li>30 godzin (45 min) zajęć teoretycznych</li>
              <li>30 godzin (60 min) zajęć praktycznych</li>
              <li>egzaminu wewnętrznego w cenie kursu</li>
              <li>profesjonalnego szkolenia z pierwszej pomocy podczas zajęć teoretycznych</li>
              <li>badania lekarskiego na miejscu</li>
              <li>zestawu testów i materiałów dydaktycznych na własność</li>
            </ul>
          </Col>
        </Row>
        <Row className="pb-md-0 pb-5">
          <Col lg={2} md={2} xs={2} className="offer__corner"></Col>
          <Col lg={9} md={11} xs={10} className="offer__sum">
            <h3>{price}zł</h3>
          </Col>
        </Row>
        <Row className="d-flex flex-row-reverse justify-content-right">
          <Col md={4}><Link to="/contact"><button>zapisz się!</button></Link></Col>
        </Row>
        </Col>
    </Row>
  </section>
  )
}

export default OfferSection
