import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import News from '../news';

function CoursesSection({ news }) {
    let [index, setIndex] = useState(0);
    const onPreviousClick = () => {
        setIndex(index + 3);
    }
    const onNextClick = () => {
        setIndex(index - 3);
    }
    const onResetClick = () => {
        setIndex(0);
    }

    const news0 = !!news[index]
        ? <News date={news[index].date} description={news[index].description}></News>
        : null;

    const news1 = !!news[index + 1]
        ? <News date={news[index + 1].date} description={news[index + 1].description}></News>
        : null;

    const news2 = !!news[index + 2]
        ? <News date={news[index + 2].date} description={news[index + 2].description}></News>
        : null;

    const previous = index < (news.length - 3) 
        ? (<button className="button courses__previous" onClick={onPreviousClick}>Poprzednie</button>) 
        : null;

    const next = index < (news.length - 3) && index !== 0 
        ? (<button className="button courses__next" onClick={onNextClick}>Następne</button>) 
        : null;

    const newest = index >= (news.length - 3)
        ? (<button className="button courses__newest" onClick={onResetClick}>Najnowsze</button >)
        : null;

    
return (
    <section id="courses" className="courses pt-5">
        <Row className="section__title">
            <Col md={6}>
                <Row>
                    <Col md={3}></Col>
                    <Col md={3}>
                        <h1 className="pl-3">
                            Aktualności
                  </h1>
                    </Col>
                </Row>
                <Row>
                    <Col md={7} sm={7} xs={8}>
                        <div className="under-titler"></div>
                    </Col>
                </Row>
            </Col>
        </Row>
        <Row className="section d-flex justify-content-center px-0">
                <Col md={8} xs={12} className="courses__news" >
                    {news0}
                    {news1}
                    {news2}
                </Col>
        </Row>
        <Row className="button_row d-flex justify-content-center px-0">
            <Col className="courses__more-button-row" md={8} xs={12}>
                {previous}
                {next}
                {newest}
            </Col>
        </Row>

    </section>
)
}

export default CoursesSection
