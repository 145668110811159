import React from "react";
import { Col, Row } from 'reactstrap';
import fb from '../../../static/assets/images/fb.svg';

function FooterSection({course}) {
  return (
  <section id="contact" className="contact">
    <Row className="section__title">
        <Col md={8}>
          <Row>
            <Col md={3}></Col>
            <Col md={3}>
                <h1 className="pl-3 pl-md-0 pl-lg-0 pt-5">
                  Kontakt
                </h1>
            </Col>
          </Row>
          <Row>
            <Col md={5} sm={6} xs={7}>
              <div className="under-titler"></div>
            </Col>
          </Row>
      </Col>
    </Row>
    <Row className="d-flex justify-content-center px-5 pt-md-5 pt-3">
      <Col md={8}>
        <Row>
          <Col md={6}>
            <div className="contact__data contact__data--1">
              <h4>Ośrodek Szkolenia Kierowców L Dziwisz</h4>
              <p>tel. 601 50 86 32</p>
              <p>mail: osk-dziwisz@wp.pl</p>
              <h4>Adres:</h4>
              <p>Ośrodek Szkolenia Kierowców</p>
              <p>Krzysztof Dziwisz</p>
              <p>ul. Kozielska 1a (budynek LO4,  sala nr 102)</p>
              <p>44-100 Gliwice</p>
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe id="gmap_canvas" title="google-maps" src="https://maps.google.com/maps?q=ul.%20Kozielska%201a%2C%20Gliwice&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0">
                    </iframe>
                  </div>
                </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="contact__data contact__data--2 pt-md-0 pt-5">
            <h4 className="office-time">Biuro czynne w każdy wtorek i czwartek w godz. 15.00-16.00. </h4>
            <h4>Serdecznie zapraszamy. </h4>
            <p>NIP: 631-138-39-28</p>
            <p>REGON: 241533787</p>
            <p class="fb">
              <a href="https://www.facebook.com/OSKDziwisz/">
                <img class="fb-logo" src={fb} alt="social media link"/>
                Znajdź nas na Facebook
              </a>
            </p>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  </section>

  )
}

export default FooterSection
