import React from "react"
import { Col, Row } from 'reactstrap'
import ster1 from "../../../static/assets/images/ster1.jpg"


function AboutSection() {

  return (
    <section id="about">
      <Row className="section__title">
          <Col lg={8} sm={12}>
            <Row>
              <Col lg={3}></Col>
              <Col lg={3}>
                  <h1 className="pl-3 pl-sm-0">
                    O nas
                  </h1>
              </Col>
            </Row>
            <Row>
              <Col lg={5} sm={5} xs={6}>
                <div className="under-titler"></div>
              </Col>
            </Row>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center pt-5 px-md-0 px-5">
        <Col lg={8} md={8}>
          <Row>
            <Col lg={6} md={6} className="order-2 order-sm-1">
              <p className="text-justify">
Ośrodek Szkolenia Kierowców L Dziwisz zajmuje się szkoleniem kierowców kat. B. Nasi instruktorzy mają <span>wieloletnie doświadczenie</span> w prowadzeniu pojazdów.
Nauka u nas nie jest stresem. Jest wysiłkiem, który szybko się zwraca w postaci <span>wielkiej satysfakcji i radości</span>. Instruktorzy są doskonale przygotowani pedagogicznie do prowadzenia kursu, co jest bardzo ważne dla efektów szkolenia. Zapewnią Ci <span>poczucie bezpieczeństwa za kierownicą</span>, ale zadbają też o to żebyś sam nauczył się zwracania szczególnej uwagi na bezpieczeństwo. Naszą szkołę cechuje <span>bardzo wysoka zdawalność</span> nie tylko części teoretycznej ale też praktycznej. 
              </p>
              <p className="text-center"><span>Nauczymy Cię stosować przepisy Kodeksu Drogowego w praktyce!</span>
              </p>
            </Col>
            <Col lg={6} md={6} className="order-1 order-sm-2 pb-5 pb-md-0">
                    <img className="img-fluid about__image" src={ster1} alt="steering wheel"/>
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  )
}

export default AboutSection

