// import React from "react"
import React from "react"
import { Col, Row } from 'reactstrap'
import yaris1 from '../../../static/assets/images/yaris1.jpg'
import { Link } from "gatsby"

function MainSection({latestCourseDate}) {
  return (
    <section className="main">
      <Row className="d-flex justify-content-center px-md-0 px-4">

      <Col lg={8} md={12}>
        <Row className="main__content">
          <Col md={6} sm={12} className="pb-4 pb-md-0 pl-md-0">
            <img src={yaris1} className="img-fluid main__img" alt="Example class car"/>
          </Col>
          <Col md={6} sm={12} className="text-center text-md-left pr-md-0">
            <h2>OSK Krzysztof Dziwisz</h2>
            <div className="underline"></div>
            <h4>Najbliższy kurs: {latestCourseDate}</h4>
            <p>Zajmujemy sie szkoleniem kandydatów na kierowców kat. B prawa jazdy oraz doskonaleniem techniki jazdy.
             Nasi instruktorzy dysponują ogromną wiedzą potwierdzoną przez rzeszę zadowolonych kursantów,
              jeżdzącycy pewnie, bezpiecznie i zgodnie z przepisami. </p>
            <Link to="/contact"><button>zapisz się</button></Link>
          </Col>
        </Row>
        </Col>
      </Row>
      <div className="down-arrow bounce mt-4 mt-md-0"></div>
    </section>

  )
}

export default MainSection
