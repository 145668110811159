import { graphql } from 'gatsby';
import { format, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale'
import React from "react";
import Helmet from 'react-helmet';
import { Container } from 'reactstrap';
import Layout from "../components/layout";
import AboutSection from '../components/sections/about';
import CoursesSection from '../components/sections/courses';
import DescriptionSection from '../components/sections/description';
import FooterSection from '../components/sections/footer';
import MainSection from '../components/sections/main';
import OfferSection from '../components/sections/offer';


const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {

  const parseAndFormatISO = (date, formatString) => format(parseISO(date), formatString, pl);

  const price = edges
    .filter(edge => !!edge.node.frontmatter.price) // You can filter your posts based on some criteria
    .map(edge => edge.node.frontmatter.price)
    .pop()

  const pricePerHour = edges
    .filter(edge => !!edge.node.frontmatter.pricePerHour) // You can filter your posts based on some criteria
    .map(edge => edge.node.frontmatter.pricePerHour)
    .pop()

  const latestCourseDate = edges
    .filter(edge => !!edge.node.frontmatter.latest_course_date) // You can filter your posts based on some criteria
    .map(edge => parseAndFormatISO(edge.node.frontmatter.latest_course_date, 'dd.MM.yyyy HH:mm'))
    .pop()

  const courses = edges 
    .filter(edge => !!edge.node.excerpt && !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => ({ 
      ...edge.node.frontmatter, 
      description: edge.node.excerpt
    }))
    .reverse()


  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
        <link rel="canonical" href="https://osk-dziwisz.pl" />
      </Helmet>
      <Container className="px-0" fluid={true}>
        <MainSection latestCourseDate={latestCourseDate}/>
        <AboutSection/>
        <OfferSection price={price}/>
        <DescriptionSection pricePerHour={pricePerHour}/>
        <CoursesSection news={courses}/>
        <FooterSection/>
      </Container>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___created_at] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 10000)
          frontmatter {
            title
            date
            price
            pricePerHour
            latest_course_date
            created_at
          }
        }
      }
    }
  }
`