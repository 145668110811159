import React from "react"
import { Col, Row } from 'reactstrap'
import elka1 from '../../../static/assets/images/elka1.png'
import elka2 from '../../../static/assets/images/elka2.png'


function DescriptionSection({pricePerHour}) {
  return (
    <section id="description" className="pt-md-5 py-2">
      <Row className="justify-content-center  px-5 px-md-0">
        <Col md={8}>
        <Row className='d-flex flex-md-nowrap flex-wrap align-items-center'>
          <div className="description__car" md={5}>
            <div>
              <img src={elka1} className="img-fluid description__img" alt="Example class car drawing"/>
            </div>
          </div>
          <div>
            <p className="text-justify">
              Jazdy odbywają na samochodach Toyota Yaris, rocznik 2018 - 6 biegów (identyczny jak na egzaminie).
            </p>
            <p className="text-justify">
              Dysponujemy dobrze oświetlonym placem manewrowym w zakresie szkolenia kat. B. Jazdy odbywają się na trasach egzaminacyjnych Rybnika lub Bytomia, mogą rozpoczynać się lub kończyć na domu kursanta jeśli znajduje się w obrębie Gliwic.
            </p>
            <p className="text-justify">
              Istnieje możliwość opłacenia kursu w nieoprocentowanych ratach.
            </p>
          </div>
        </Row>
        <Row className="underline">
          <div className="underline__block"></div>
        </Row>
        <Row className="align-items-center">
          <Col md={5} className="order-2 order-md-1 px-md-0">
            <p className="text-justify">
              Zapisy na kurs przyjmujemy poprzez formularz na stronie, wiadomość email na adres znajdujący się na w sekcji kontakty bądź telefonicznie.
            </p>
            <p className="text-justify">
              Oferujemy także dodatkowe jazdy (1h za {pricePerHour} zł) a także szkolenia dodatkowe oraz uzupełniające.
            </p>
            <p className="text-justify">
              Dzięki naszemu doświadczeniu zostaniesz kierowcą, który rozumie i widzi ruch drogowy z wielu perspektyw!
            </p>
          </Col>

          <Col className="d-flex justify-content-center order-md-1 pr-0" md={7}>
              <img src={elka2} className="img-fluid description__img" alt="Steerwheel and book."/>
          </Col>
        </Row>

        </Col>
      </Row>

    </section>
  )
}

export default DescriptionSection
