import React from 'react';
import { Row, Col } from 'reactstrap'

function News({ date, description }) {

    return (
        <Col className="news" md={12} xs={12}>
          <Row className="mb-3 d-flex justify-content-right">
            <Col md={9} xs={6}></Col>
            <Col md={3} xs={6} className="courses__date">
              {new Date(date).toLocaleDateString('pl-PL')}
            </Col>
          </Row>
          <Row>
            <Col className="d-flex mr-3 mr-md-0" md={12}>
              <p className="text-justify">
                {description}
              </p>
            </Col>
          </Row>
        </Col>
      )
}

export default News;
